import React from "react";
import Dashboard from "./Components/Dashboard";


const DashboardIndex: React.FC = () => {

  
  return <Dashboard />;
};
export default DashboardIndex;
