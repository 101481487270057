import { useState } from "react";
import ApiService from "../../../service/ApiService";  // Assuming this is the service to handle API calls
import { apiPaths } from "../../../service/apiPaths";  // Assuming these are the paths to your API
import { VerifyRequest, VerifyResponse } from "./Verify.props";
import { useSelector } from "react-redux";
import { Navigate , useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const useVerify = () => {
  const [otp, setOtp] = useState<string>("");      // OTP code state
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate()

  // Function to verify OTP code
  const verifyCode = async ({email , otp }: VerifyRequest): Promise<VerifyResponse | null> => {
    setLoading(true);
    setError(null);

    try {
      const response: VerifyResponse = await ApiService({
        method: "POST",
        endpoint: apiPaths.VerifyOtp,  // Adjust to your actual endpoint
        data: { email, otp},  // Pass the email and OTP
      });

      return response;  // Return API response
    } catch (error: any) {
      const errorMessage = error.message || "Failed to verify OTP";
      setError(errorMessage);
      console.error("Verification error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the form submission
  const handleSubmit = async (email :string , otp:string) => {
    const response = await verifyCode({ email, otp }); 
    console.log(response)
    if (response && response.success) {
      return true;  // If successful, return true
    }
    return false;  // If failed, return false
  };

  return { otp, setOtp, loading, error, handleSubmit };
};

export const resendOtp = async(email:string)=>{
  try{
    const response = await ApiService({
      method:"POST" ,
      endpoint:apiPaths.ResendOtp ,
      data:{email}
    })
    if(response.success){
      toast.success("OTP Resend Successfully");
    }

  }catch(err){
}}

  export const setPassword = async( otp:string , password:string , email:string )=>{
    try{
      const response = await ApiService({
        method:"POST" ,
        endpoint:apiPaths.SetPassword ,
        data:{email:email , password:password , otp:otp}
      })
      console.log(response)
      if(response.success){
        toast("Password Changed Successfully");
        return response
      }
      return null
  
    }catch(err){ 
      toast.error("Error Occured");
      return null
    }}

export default useVerify