import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { LinksArray } from "./MockSidebar";


interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  setLogOutModal:(arg:boolean)=>void;

}

const Sidebar = ({ sidebarOpen, setSidebarOpen,setLogOutModal }: SidebarProps) => {
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  // Handle click outside the sidebar to close it
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [sidebarOpen]);

  // Handle Escape key to close the sidebar
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [sidebarOpen]);

  const clickHandler = (name:string)=>{
  
    if(name.toLocaleLowerCase()==="logout"){
      setLogOutModal(true)
    }
  };

  // Render the sidebar links
 
  const renderLinks = (linksArray: any[]) => (
    <>
     {linksArray.map((linkItem, index) => {
  if (linkItem.name === "Logout") {
    return (
      <button
        key={index}
        onClick={(e) => {
          clickHandler(linkItem.name); 
          e.stopPropagation();
        }}
        className="link-button px-4 py-[18px] rounded-[10px] text-white"
      >
        <div className="flex gap-6 items-center">
          {linkItem.img}
          <p className="text-white text-[15px]">{linkItem.name}</p>
        </div>
      </button>
    );
  }

  return (
    <NavLink
      key={index}
      to={linkItem.link}
      className={({ isActive }) =>
        `link-button px-4 py-[18px] rounded-[10px] ${
          isActive ? "bg-[#5dcbba]" : ""
        }`
      }
      onClick={(e) => {
        clickHandler(linkItem.name);
        e.stopPropagation();
      }}
    >
      <div className="flex gap-6 items-center">
        {linkItem.img}
        <p className="text-white text-[15px]">{linkItem.name}</p>
      </div>
    </NavLink>
  );
})}

    </>
  );
  

  return (
    <section className="w-full sticky top-[20px]">
      <div className="flex flex-col bg-[#52bbab] h-full p-6 rounded-[20px] min-h-[500px]">
        {renderLinks(LinksArray)}
      </div>
    </section>
  );
};

export default Sidebar;
