import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    fullName:"" ,
    email:null ,
    password:"" ,
    phoneNumber:"" ,
    otp:""
}

const signUpSlice = createSlice({
    name:"signupSlice" ,
    initialState: initialState,
    reducers:{
        setUserDetails: (state, action) => {
            if (action.payload.fullName) {
                state.fullName = action.payload.fullName;
            }
            if (action.payload.email) {
                state.email = action.payload.email;
            }
            if (action.payload.phoneNumber) {
                state.phoneNumber = action.payload.phoneNumber;
            }
            if (action.payload.password) {
                state.password = action.payload.password;
            }
            if (action.payload.otp) {
                state.otp = action.payload.otp;
            }
        }
    }

})
export const {setUserDetails} = signUpSlice.actions
export default signUpSlice.reducer