// useSignup.ts
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignupRequest, SignupResponse } from "./Signup.props";
import ApiService from "../../../service/ApiService";
import { apiPaths } from "../../../service/apiPaths";

export const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const signup = async (
    data: SignupRequest
  ): Promise<SignupResponse | null> => {
    setLoading(true);

    try {
      const response: SignupResponse = await ApiService({
        method: "POST",
        endpoint: apiPaths.Signup,
        data,
      });

      if (response) {
        alert("Account created successfully!");
        navigate("/login"); // Redirect to login after success
        return response;
      }
    } catch (error: any) {
      const errorMessage = error.message || "Failed to create account";
      alert(errorMessage);
      console.error("Signup error:", error);
    } finally {
      setLoading(false);
    }
    return null;
  };

  // Password verification function
  const verifyPassword = (
    password: string,
    confirmPassword: string
  ): boolean => {
    // Trim any leading or trailing spaces before comparing
    if (password.trim() !== confirmPassword.trim()) {
      alert("Passwords do not match");
      return false;
    }
    return true;
  };

  return { signup, verifyPassword, loading };
};
