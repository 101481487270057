// src/hooks/useLogin.ts
import { useState } from "react";
import { apiPaths } from "../../service/apiPaths";
import ApiService from "../../service/ApiService";
import { useDispatch } from 'react-redux'
import toast from "react-hot-toast";
import axios from 'axios';
import qs from 'qs'; // Import qs to URL encode the data



export const useGetProjectManagers = () => {
  const [loading, setLoading] = useState(false);
  const [approvalLoading , setApprovalLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch()
  const[approveLoader , setApproveLoader] = useState(false)
  const[rejectLoader , setRejectLoader] = useState(false)

  const getAllProjects = async (page:string

  ): Promise<any | null> => {
    setLoading(true);
    setError(null);

    try {
      const response: any = await ApiService({
        method: "GET",
        endpoint:`${apiPaths.getAllProjectsManager}?page=${page}` , 
      });

      console.log(response.response.docs )
      console.log(response)
      

   

      // Handle successful login
      return response.response; // Return the login response data
    } catch (error: any) {
      const errorMessage = error.message || "Failed to login";
      setError(errorMessage);
      toast.error("Login error:", error);
      // console.error("Login error:", error);
      return null;
    } finally {
      setLoading(false);
    }


    
  };





const rejectApplication = async (projectId: string, reason: string) => {
  try {
    setRejectLoader(true);

    // Prepare the URL-encoded data
    const data = qs.stringify({
      reason: reason,  // encode the rejection reason
    });

    const endpoint = `http://localhost:8080/${apiPaths.processProjectManagerApplication}/${projectId}/reject`;
    console.log("Sending request to:", endpoint);
    
    // Send the POST request with URL-encoded data and credentials
    const response = await axios.post(endpoint, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Set content type for URL-encoded data
      },
      withCredentials: true,  // Include credentials (cookies, authorization headers, etc.)
    });

    console.log(response.data); // Log the response data
    return response.data;

  } catch (err) {
    console.error("Error rejecting application:", err);
    return null;
  } finally {
    setRejectLoader(false); // Stop the loader once the request is done
  }
};




  const acceptApplication = async(projectId:string)=>{
    try{
      setApprovalLoading(true)
      const data = await ApiService({
        method:"POST" ,
        endpoint:`${apiPaths.processProjectManagerApplication}/${projectId}/approve` ,
        
      })
      return data

    }catch(err){
      return null

    }finally{
      setApprovalLoading(false)
    }
  }


   const getRejectedApplication = async(page:string)=>{
    try{
      setLoading(true)
      const response:any = await ApiService({
        method:"GET" ,
        endpoint:`${apiPaths.getRejectedApplications}?page=${page}` ,

      })
      console.log(response)
      if(response.success){
        return response.response

      }else{
        return null
      }

    }catch(err){
      return null

    }finally{
      setLoading(false)
    }
  }
  const getAcceptedApplication = async(page:string)=>{
    try{
      setLoading(true)
      const response:any = await ApiService({
        method:"GET" ,
        endpoint:`${apiPaths.getAcceptedApplications}?page=${page}` ,

      })
      console.log(response)
      if(response.success){
        return response.response

      }else{
        return null
      }

    }catch(err){
      return null

    }finally{
      setLoading(false)
    }
  }

  const getProjectManagerById =async(id:string|undefined)=>{
    try{
      setLoading(true)
      const response:any = await ApiService({
          method:"GET",
          endpoint:`${apiPaths.getProjectManagerById}/${id}`,
      })
      if(response.success){
        console.log(response.response)
        return response.response

      }else{
        return null
      }

    }catch(err){
      return null

    }finally{
      setLoading(false)
    }
          
  }

  return { getAllProjects,rejectApplication,getProjectManagerById,acceptApplication,getRejectedApplication,getAcceptedApplication, loading, error  , approvalLoading , rejectLoader };
};
