import { createSlice } from "@reduxjs/toolkit";
import { ProfileResponse } from "../page/Profile/Profile.props";

export interface userSliceData{
    isLoggedIn: boolean;
    user: ProfileResponse | null;
}



const initialState:userSliceData = {
    isLoggedIn: false,
    user:{
        _id:"",
        profilePic: "" ,
        fullName: "", 
        email: '' ,
        password: '' ,
        phoneNumber: '',
        dateOfBirth: '',
        city: '' ,
        state: '' ,
        country: '', 
        postalCode: '', 
        isVerified: false, 
        blockExpires: '', 
        loginAttempts: 0,
        isProjectManager: false,
        kycStatus: 'rejected',
        roleId: '' ,
        projectManagerId: '' ,
        preferences: {
        timezone: null ,
        logoutTime: 0 
        },
        firstname:'' ,
        lastname:''

    }
  };

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state,action){
            state.user = action.payload;
        },
        setIsLoggedIn(state,action){
            state.isLoggedIn = action.payload;
        }
    }
});

export const {setUser, setIsLoggedIn} = userSlice.actions;
export default userSlice.reducer;


