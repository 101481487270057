import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/appStore";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
 


  return (

    <>
      <h1 className="font-semibold text-[22px]">Dashboard</h1>
    </>
  );
};

export default Dashboard;
